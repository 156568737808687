import { Space, Spin } from "antd-4.21.7";
import React from "react";

type Props = React.PropsWithChildren<{
  isLoading?: boolean;
}>;

export const PageLayout: React.FC<Props> = ({ children, isLoading }) => (
  <Space
    direction="vertical"
    size="large"
    style={{
      margin: "0 auto",
      padding: "20px",
      maxWidth: "100%",
      width: "100%",
    }}
  >
    <div>
      {isLoading && (
        <>
          <div
            className="ant-modal-mask"
            style={{ backgroundColor: "rgba(255,255,255, 0.5)" }}
          />
          <div
            className="ant-modal-wrap"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin size="large" />
          </div>
        </>
      )}

      {children}
    </div>
  </Space>
);
