import { Form } from "antd-4.21.7";
import { formItemLayout } from "controls/ControlGroup/ControlGroup";

interface FormFieldProps {
  title?: string;
  field: React.ReactNode;
}

export const FormField = ({ title, field }: FormFieldProps) => (
  <Form.Item
    {...formItemLayout}
    label={<strong style={{ whiteSpace: "break-spaces" }}>{title}</strong>}
    labelAlign="right"
  >
    {field}
  </Form.Item>
);
