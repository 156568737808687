import { format } from "date-fns";

export const formatDateTime = (date: string) => {
  if (!date) {
    return "";
  }

  try {
    const dateObj = new Date(date);

    return format(dateObj, "yyyy-MM-dd HH:mm");
  } catch (e) {
    return "Invalid date";
  }
};
