import { useMemo, useState } from "react";
import {
  ClockCircleOutlined,
  DatabaseOutlined,
  FormOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Typography, Row, Col, Tabs } from "antd-4.21.7";
import { ACL, PERMISSIONS } from "components/ACL";
import { MfeLink } from "components/MfeLink";
import { PageLayout } from "components/PageLayout";
import { ROUTES } from "../../routes";
import { ABTestsTable } from "./components/ABTestsTable";
import { useExperimentList } from "./hooks/useExperimentList";
import { useDeleteExperiment } from "./hooks/useDeleteExperiment";
import {
  AbTestingExperimentFilter,
  AbTestingExperimentWithRegions,
  extractConditionVars,
  filterExperiments,
  groupExperiments,
} from "./utils";
import { ABTestsFilter } from "./components/ABTestsFilter";

export const ABTestsOverview = () => {
  const { experiments, isLoading } = useExperimentList();

  const experimentsWithRegion = useMemo<AbTestingExperimentWithRegions[]>(
    () =>
      !experiments?.length
        ? []
        : experiments.map((experiment) => ({
            ...experiment,
            regions: extractConditionVars(experiment.condition, [
              "locale",
              "country",
            ]),
          })),
    [experiments]
  );

  const [filter, setFilter] = useState<AbTestingExperimentFilter>({});

  const {
    activeExperiments,
    pausedExperiments,
    draftExperiments,
    expiredExperiments,
  } = useMemo(
    () => groupExperiments(filterExperiments(experimentsWithRegion, filter)),
    [experiments, filter]
  );

  const { deleteExperiment, isLoading: isDeleteExperimentInProgress } =
    useDeleteExperiment();

  return (
    <PageLayout>
      <Row justify="space-between" align="middle" style={{ marginBottom: 10 }}>
        <Col>
          <Typography.Title level={1} style={{ marginBottom: 0 }}>
            <DatabaseOutlined /> Experiments
          </Typography.Title>
        </Col>
        <Col>
          <ACL allow={PERMISSIONS.CREATE_EXPERIMENT}>
            <MfeLink
              size="large"
              type="primary"
              to={ROUTES.create({})}
              data-qa-selector="new-experiment"
              icon={<PlusOutlined />}
            >
              New Experiment
            </MfeLink>
          </ACL>
        </Col>
      </Row>

      <ABTestsFilter
        experiments={experimentsWithRegion}
        setFilter={setFilter}
      />

      <Tabs
        defaultActiveKey="active"
        size="large"
        tabBarStyle={{ marginBottom: 0 }}
      >
        <Tabs.TabPane
          key="active"
          tab={
            <>
              <PlayCircleOutlined />
              Active
            </>
          }
        >
          <ABTestsTable
            data-qa-selector="active-experiments-table"
            experiments={activeExperiments}
            isLoading={isLoading}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="paused"
          tab={
            <>
              <PauseCircleOutlined />
              Paused
            </>
          }
        >
          <ABTestsTable
            data-qa-selector="paused-experiments-table"
            experiments={pausedExperiments}
            isLoading={isLoading || isDeleteExperimentInProgress}
            onDelete={deleteExperiment}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="draft"
          tab={
            <>
              <FormOutlined />
              Draft
            </>
          }
        >
          <ABTestsTable
            data-qa-selector="draft-experiments-table"
            experiments={draftExperiments}
            isLoading={isLoading || isDeleteExperimentInProgress}
            onDelete={deleteExperiment}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="expired"
          tab={
            <>
              <ClockCircleOutlined />
              Expired
            </>
          }
        >
          <ABTestsTable
            data-qa-selector="expired-experiments-table"
            experiments={expiredExperiments}
            isLoading={isLoading || isDeleteExperimentInProgress}
            onDelete={deleteExperiment}
          />
        </Tabs.TabPane>
      </Tabs>
    </PageLayout>
  );
};
