import { useQuery } from "react-query";
import { GET_STEP_TYPES } from "gql/queries";
import { CheckoutAllStepTypes, CheckoutStepType } from "gql";
import { SelectOption } from "../types";
import { useMemo } from "react";

const CHECKOUT_INDEX = "CHECKOUT_INDEX";
const CHECKOUT_AUTH = "CHECKOUT_AUTH";
const INDEX_VIEW_NAME = CHECKOUT_INDEX;
const UNSKIPPABLE_VIEW_NAMES = [CHECKOUT_INDEX, CHECKOUT_AUTH];

interface CheckoutAllStepTypesData {
  retailCheckoutStepTypes: CheckoutAllStepTypes;
}

export const useExperimentRelatedData = () => {
  const { data, isLoading } = useQuery<CheckoutAllStepTypesData>(
    GET_STEP_TYPES,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const multiStepExperimentViewNameOptions: SelectOption[] = useMemo(
    () =>
      (data?.retailCheckoutStepTypes?.steps || []).map(
        (step: CheckoutStepType) => ({
          value: step.viewName,
          label: step.viewName,
        })
      ),
    [data?.retailCheckoutStepTypes?.steps]
  );

  const nextStepOptions: SelectOption[] = useMemo(
    () => [
      { value: "", label: "Original" },
      ...multiStepExperimentViewNameOptions.filter(
        (step) => step.value !== INDEX_VIEW_NAME
      ),
    ],
    [multiStepExperimentViewNameOptions]
  );

  const skipStepOptions: SelectOption[] = useMemo(
    () =>
      multiStepExperimentViewNameOptions.filter(
        (step) => !UNSKIPPABLE_VIEW_NAMES.includes(step.value)
      ),
    [multiStepExperimentViewNameOptions]
  );

  return {
    multiStepExperimentViewNameOptions,
    nextStepOptions,
    skipStepOptions,
    isLoading: isLoading || !multiStepExperimentViewNameOptions?.length,
  };
};
