import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const uuidRegex =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

const makeUuidOneOfSchema = () =>
  Yup.string()
    .nullable()
    .trim()
    .matches(uuidRegex, {
      excludeEmptyString: true,
      message: "Must be a valid UUID",
    })
    .test(
      "oneOfRequired",
      "One of an Order ID, User ID or, Browser ID is required",
      function (_) {
        return (
          this.parent.orderId || this.parent.userId || this.parent.browserId
        );
      }
    )
    .transform((_, value) => {
      return value === "" ? null : value;
    });

const schema = Yup.object({
  orderId: makeUuidOneOfSchema(),
  userId: makeUuidOneOfSchema(),
  browserId: makeUuidOneOfSchema(),
}).required();

export const validationResolver = yupResolver(schema);
