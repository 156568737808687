import { Card } from "antd-4.21.7";
import { useContext } from "react";
import { useFormContext } from "react-hook-form";

import { VariantsFieldsContext } from "../config";
import { CustomInputFormController } from "controls/Input";
import CustomSelectFormController from "controls/Select";
import { IndexRow } from "../IndexRow";
import { VIEW_VARIANT_OPTIONS } from "../../ABTestForm/config";
import { arraysEqual } from "helpers/array";

export const SimpleVariant = ({ index }) => {
  const {
    formState: { errors },
    watch,
    trigger,
  } = useFormContext();

  const { fieldName, isDisabled } = useContext(VariantsFieldsContext);

  const variants = watch(fieldName);
  const { viewVariant, viewParams } = variants[index];
  const currentVariantRecord = [viewVariant || "", viewParams || ""];

  const isDuplicate =
    variants.filter(({ viewVariant, viewParams }) =>
      arraysEqual(currentVariantRecord, [viewVariant || "", viewParams || ""])
    ).length > 1;

  const isOriginalViewVariant = !viewVariant;

  const triggerVariantValidation = () => {
    trigger(fieldName);
  };

  return (
    <Card
      key={index}
      style={{
        margin: "16px 0",
        ...(index % 2 === 0 ? { backgroundColor: "rgba(0, 0, 0, 0.02)" } : {}),
      }}
      data-qa-selector={`variant-${index}`}
    >
      <IndexRow>{index}</IndexRow>
      <CustomSelectFormController
        label="View Variant"
        name={`${fieldName}.${index}.viewVariant`}
        type="text"
        dataQa="view-variant"
        isDisabled={isDisabled}
        error={errors[fieldName]?.[index]?.viewVariant}
        options={VIEW_VARIANT_OPTIONS}
        onChange={triggerVariantValidation}
        {...(isDuplicate || !isOriginalViewVariant
          ? {
              hasFeedback: true,
              validateStatus: "warning",
              help: isDuplicate
                ? '"View Variant" and "View Params" combination should be unique'
                : !isOriginalViewVariant
                ? `Make sure Git branch ab-test-${viewVariant} exists for MFE`
                : undefined,
            }
          : {})}
      />
      <CustomInputFormController
        label="View Params"
        placeholder="JSON"
        name={`${fieldName}.${index}.viewParams`}
        type="area"
        error={errors[fieldName]?.[index]?.viewParams}
        dataQa="view-params"
        isDisabled={isDisabled}
        onChange={triggerVariantValidation}
        tooltip='For example, { "index": 1 } or { "name": "test" }'
      />
      <CustomInputFormController
        name={`${fieldName}.${index}.weightPercents`}
        label="Weight Percents"
        type="number"
        min="1"
        max="99"
        error={errors[fieldName]?.[index]?.weightPercents}
        dataQa="weight-percents"
        onChange={triggerVariantValidation}
        isDisabled={isDisabled}
        required
      />
    </Card>
  );
};
