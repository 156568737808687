import { intersection, flow, toPairs, map, isArray, isEmpty } from "lodash/fp";
import { SCHEMA, DOMAINS } from "../constants";

export const hasPermissions = (userPerms, allowPerms) => {
  const result = intersection(allowPerms, userPerms);
  return !isEmpty(result);
};
export const mapToGQLPermission = (
  perm,
  domain = DOMAINS.AB_TESTING_MANAGEMENT
) => {
  let schema = SCHEMA;
  return `${schema}://${domain}/${perm}`;
};

export const getGQLPermissions = (allow, domain) => {
  const perms = isArray(allow) ? allow : [allow];
  return flow(
    toPairs,
    map(([i, perm]) =>
      mapToGQLPermission(perm, isArray(domain) ? domain[i] : domain)
    )
  )(perms);
};
