import React from "react";
import { useFormContext, Controller, ControllerProps } from "react-hook-form";
import { noop, get, flow, filter, head } from "lodash/fp";

import { ControlGroup } from "../ControlGroup";

type FormControllerType = {
  error?: any;
  required?: boolean;
  inputRef?: any;
  label?: string | React.ReactNode;
  name: string;
  onChange?: (value: any, label: any) => void;
  subTitle?: string;
  controllerProps?: Partial<ControllerProps>;
} & { [x: string]: any };

export const createFormController = ({ Control }) => {
  const FormController = ({
    label,
    name,
    hasFeedback,
    validateStatus,
    help,
    error: errorObj,
    onChange: propsOnChange = noop,
    inputRef: propsInputRef,
    required,
    subTitle,
    tooltip,
    controllerProps,
    ...controlProps
  }: FormControllerType) => {
    const { control, formState } = useFormContext();
    const { errors } = formState;
    const error = errors[name] || errorObj;

    const inputRef = propsInputRef;

    return (
      <Controller
        name={name}
        control={control}
        render={({
          field: {
            ref,
            onChange: controllerOnChange,
            onBlur: controllerOnBlur,
            value,
            name,
          },
        }) => (
          <ControlGroup
            label={label}
            required={required}
            error={error}
            subTitle={subTitle}
            name={name}
            tooltip={tooltip}
            hasFeedback={hasFeedback}
            validateStatus={validateStatus}
            help={help}
            element={
              <Control
                {...controlProps}
                name={name}
                hasFeedback={hasFeedback}
                onBlur={controllerOnBlur}
                onChange={(event) => {
                  controllerOnChange(event);
                  const label = flow(
                    get(["options"]),
                    filter(
                      (option: { value: string }) =>
                        option.value === event?.target?.value
                    ),
                    head,
                    get("label")
                  )(controlProps);

                  propsOnChange(event, label);
                  controllerOnBlur();
                }}
                value={value}
                inputRef={inputRef || ref}
                error={error}
              />
            }
          />
        )}
        {...controllerProps}
      />
    );
  };

  return FormController;
};
