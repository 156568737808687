import {
  Config,
  FieldOrGroup,
  Widget,
  FieldSettings,
  WidgetConfigForType,
} from "react-awesome-query-builder";
import baseConfig from "react-awesome-query-builder/lib/config/antd";
import * as Yup from "yup";
import { KnownVarOperator, KnownConditionVar } from "./types";

interface FieldConfigForType {
  type: string;
  operators?: string[];
  fieldSettings?: FieldSettings;
  widgetConfig?: WidgetConfigForType;
}

const TIME_FORMAT = "HH:mm:ss";
const LOCAL_DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss";
const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";

const validateUuid = (string: string): null | string => {
  const isValidUuid = Yup.string().uuid().isValidSync(string);

  return isValidUuid ? null : "Must be a valid UUID string";
};

const validateInt = (value: unknown): null | string =>
  Number.isInteger(value) ? null : "Must be a valid integer";

const makeFieldFromConditionVar = (
  conditionVar: KnownConditionVar
): FieldOrGroup => {
  const defaultOps = ["equal", "not_equal", "is_null", "is_not_null"];
  const stringOps = [...defaultOps, "matches", "contains"];
  const numericOps = [
    "equal",
    "not_equal",
    "greater",
    "greater_or_equal",
    "less",
    "less_or_equal",
    "is_null",
    "is_not_null",
  ];
  const dateTimeOps = [
    "is_on",
    "is_not_on",
    "is_before",
    "is_before_or_equal",
    "is_after",
    "is_after_or_equal",
    "is_between",
    "is_null",
    "is_not_null",
  ];
  const intField: FieldConfigForType = {
    type: "number",
    operators: numericOps,
    fieldSettings: { validateValue: validateInt },
  };
  const dateTimeField: FieldConfigForType = {
    type: "datetime",
    operators: dateTimeOps,
    fieldSettings: {
      timeFormat: TIME_FORMAT,
      valueFormat: DATE_TIME_FORMAT,
    },
  };
  const localDateTimeField: FieldConfigForType = {
    type: "datetime",
    operators: dateTimeOps,
    fieldSettings: {
      timeFormat: TIME_FORMAT,
      valueFormat: LOCAL_DATE_TIME_FORMAT,
    },
  };
  const dateField: FieldConfigForType = {
    type: "date",
    operators: dateTimeOps,
  };
  const fieldByType: Record<KnownVarOperator, FieldConfigForType> = {
    BOOLEAN: {
      type: "boolean",
      operators: defaultOps,
    },
    STRING: {
      type: "text",
      operators: stringOps,
      fieldSettings: { maxLength: 400 },
    },
    INTEGER: intField,
    LONG: intField,
    ENUM: {
      type: "select",
      operators: [
        "equal",
        "not_equal",
        "select_any_in",
        "is_null",
        "is_not_null",
      ],
      fieldSettings: { listValues: conditionVar.values },
      widgetConfig: {
        // It needs to be forced otherwise multiple selection (select_any_in) doesn't work
        operators: defaultOps,
      },
    },
    UUID: {
      type: "text",
      operators: defaultOps,
      fieldSettings: { validateValue: validateUuid },
    },
    DATETIME: dateTimeField,
    OFFSETDATETIME: dateTimeField,
    LOCALDATETIME: localDateTimeField,
    LOCALDATE: dateField,
    ZIPCODE: {
      type: "text",
      operators: ["zip_code_in"],
    },
  };

  const {
    type,
    operators = defaultOps,
    fieldSettings,
    widgetConfig,
  } = fieldByType[conditionVar.type];

  return {
    type,
    fieldSettings,
    operators,
    defaultOperator: operators[0] ?? "equal",
    label: conditionVar.variable.split(".").join(" > "),
    valueSources: ["value"],
    widgets: {
      [type]: widgetConfig ?? { operators },
    },
  };
};

const makeDateWidget = (baseWidget: Widget): Widget => ({
  ...baseWidget,
  // Dates shouldn't be boxed in a JS Date object (default behavior),
  // leave them as plain string preserving formatting (specified in the "valueFormat" prop)
  jsonLogic: (date: string) => date,
});

export const makeBuilderConfig = (vars: KnownConditionVar[]): Config => ({
  ...baseConfig,
  widgets: {
    ...baseConfig.widgets,
    datetime: makeDateWidget(baseConfig.widgets.datetime),
    date: makeDateWidget(baseConfig.widgets.date),
  },
  settings: {
    ...baseConfig.settings,
    showNot: true,
    showErrorMessage: true,
    // It's important to use a separator other than "."
    // as "." is used in field names for a different purpose
    fieldSeparator: "@@",
  },
  operators: {
    equal: {
      ...baseConfig.operators.equal,
    },
    not_equal: baseConfig.operators.not_equal,
    less: baseConfig.operators.less,
    less_or_equal: baseConfig.operators.less_or_equal,
    greater: baseConfig.operators.greater,
    greater_or_equal: baseConfig.operators.greater_or_equal,
    select_any_in: {
      ...baseConfig.operators.select_any_in,
      reversedOp: null, // JsonLogic doesn't support reversedOp: select_not_any_in
    },
    is_null: {
      label: "Is null",
      cardinality: 0,
      jsonLogic: "is_null",
      reversedOp: "is_not_null",
    },
    is_not_null: {
      label: "Is not null",
      cardinality: 0,
      jsonLogic: "is_not_null",
      reversedOp: "is_null",
    },
    matches: {
      label: "matches",
      cardinality: 1,
      jsonLogic: "matches",
    },
    contains: {
      label: "contains",
      cardinality: 1,
      jsonLogic: "contains",
    },
    is_on: {
      label: "==",
      cardinality: 1,
      jsonLogic: "is_on",
      reversedOp: "is_not_on",
    },
    is_not_on: {
      label: "!=",
      cardinality: 1,
      jsonLogic: "is_not_on",
      reversedOp: "is_on",
    },
    is_before: {
      label: "<",
      cardinality: 1,
      jsonLogic: "is_before",
      reversedOp: "is_after_or_equal",
    },
    is_before_or_equal: {
      label: "<=",
      cardinality: 1,
      jsonLogic: "is_before_or_equal",
      reversedOp: "is_after",
    },
    is_after: {
      label: ">",
      cardinality: 1,
      jsonLogic: "is_after",
      reversedOp: "is_before_or_equal",
    },
    is_after_or_equal: {
      label: ">=",
      cardinality: 1,
      jsonLogic: "is_after_or_equal",
      reversedOp: "is_before",
    },
    is_between: {
      label: "Between",
      cardinality: 2,
      jsonLogic: "is_between",
    },
    zip_code_in: {
      label: "ZIP code in",
      cardinality: 1,
      jsonLogic: "zip_code_in",
    },
  },
  fields: Object.fromEntries(
    vars.map((conditionVar) => [
      conditionVar.variable,
      makeFieldFromConditionVar(conditionVar),
    ])
  ),
});
