import { CSSProperties, useCallback, useState, useEffect } from "react";
import { Collapse } from "antd-4.21.7";
import { DownOutlined, RightOutlined, StopOutlined } from "@ant-design/icons";
import ActionButton from "./ActionButton";

const INPUT_STYLES = {
  display: "block",
} as CSSProperties;

const Input = ({ isEmpty, onOpen, title, value }) => {
  const [activePanel, setActivePanel] = useState(null);
  const panelTitle = isEmpty ? "No condition specified" : "View details";
  const isCollapsible = !isEmpty ? undefined : "disabled";

  useEffect(() => {
    if (isEmpty) {
      setActivePanel(undefined);
    }
  }, [isEmpty]);

  const handleChange = (key) => {
    setActivePanel(key);
  };

  const renderAction = () => (
    <ActionButton title={title} onClick={onOpen} isEmpty={isEmpty} />
  );

  const getExpandIcon = useCallback(
    ({ isActive }) => {
      if (isEmpty) {
        return <StopOutlined />;
      }

      const ArrowComponent = isActive ? DownOutlined : RightOutlined;

      return <ArrowComponent />;
    },
    [isEmpty]
  );

  return (
    <Collapse
      collapsible={isCollapsible}
      accordion={!isCollapsible}
      activeKey={activePanel}
      onChange={handleChange}
      style={{ backgroundColor: isEmpty ? undefined : "transparent" }}
      expandIcon={getExpandIcon}
    >
      <Collapse.Panel
        key="condition-details"
        header={<span style={INPUT_STYLES}>{panelTitle}</span>}
        extra={renderAction()}
      >
        {!isEmpty ? <pre>{value}</pre> : null}
      </Collapse.Panel>
    </Collapse>
  );
};

export default Input;
