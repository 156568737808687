import { notification, Tooltip, Button } from "antd-4.21.7";
import { DEFAULT_STYLES } from "constants/notifications";

const handleError = (error) => {
  const details = error.description || error.message;
  const handleCopy = () => {
    navigator.clipboard.writeText(details);
  };

  notification.open({
    message: error.errorType,
    description: (
      <Tooltip placement="right" title="Copied!" trigger="click">
        <Button onClick={handleCopy}>Copy error details</Button>
      </Tooltip>
    ),
    style: DEFAULT_STYLES,
  });
};

export default (errors) => {
  errors.forEach(handleError);
};
