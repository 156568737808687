import { useQuery } from "react-query";

import { GET_EXPERIMENT_COUNTERS } from "gql/queries";
import { config } from "config";

type CountersData = {
  data?: { counters: number[]; totalHits: number };
  isLoading: boolean;
};

type ExperimentCountersData = {
  abTestingExperimentCounters: {
    counters: number[];
    totalHits: number;
  };
};

export const useExperimentCounters = (id: string): CountersData => {
  const { data, isLoading } = useQuery<ExperimentCountersData>(
    [
      GET_EXPERIMENT_COUNTERS,
      { experimentId: id, environment: config.APP_ENV },
    ],
    {
      enabled: !!id,
    }
  );

  return {
    data: data?.abTestingExperimentCounters,
    isLoading,
  };
};
