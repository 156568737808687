import { FC, useEffect, useState } from "react";
import { Button, Tooltip, Popover } from "antd-4.21.7";
import {
  CopyOutlined,
  FileAddOutlined,
  CheckOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import { AbTestingExperimentFormValues } from "../../types";

export const hasClipboardAPI = Boolean(
  navigator.clipboard?.writeText && navigator.clipboard?.readText
);

interface ClipboardSaveButtonProps {
  getValues: () => AbTestingExperimentFormValues;
  disabled?: boolean;
}

interface ClipboardPasteButtonProps {
  onPaste: (values: AbTestingExperimentFormValues) => void;
}

export const ClipboardSaveButton: FC<ClipboardSaveButtonProps> = ({
  getValues,
  disabled = false,
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    if (hasClipboardAPI) {
      navigator.clipboard.writeText(JSON.stringify(getValues()));
      setIsCopied(true);
    }
  };

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => setIsCopied(false), 1000);
    }
  }, [isCopied, setIsCopied]);

  return (
    <Tooltip title="Copy to clipboard!">
      <Button
        type="default"
        htmlType="button"
        icon={!isCopied ? <CopyOutlined /> : <CheckOutlined />}
        disabled={disabled}
        onClick={copyToClipboard}
      >
        Copy
      </Button>
    </Tooltip>
  );
};

export const ClipboardPasteButton: FC<ClipboardPasteButtonProps> = ({
  onPaste,
}) => {
  const [isPasted, setIsPasted] = useState(false);

  const pasteFromClipboard = () => {
    if (hasClipboardAPI) {
      navigator.clipboard.readText().then((text) => {
        onPaste(JSON.parse(text));
      });
      setIsPasted(true);
    }
  };

  useEffect(() => {
    if (isPasted) {
      setTimeout(() => setIsPasted(false), 1000);
    }
  }, [isPasted, setIsPasted]);

  return (
    <Tooltip title="Paste from clipboard!">
      <Button
        type="default"
        htmlType="button"
        icon={!isPasted ? <FileAddOutlined /> : <CheckOutlined />}
        onClick={pasteFromClipboard}
      >
        Paste
      </Button>
    </Tooltip>
  );
};

export const ClipboardInfoButton: FC = () => {
  return (
    <Popover
      content={
        <ul>
          <li>Press "Copy to clipboard" button</li>
          <li>
            Open experiment you want to create/update and press "Paste from
            clipboard" button
          </li>
          <li>Save changes</li>
        </ul>
      }
      title="Copy and Paste experiment data between different experiments."
    >
      <Button
        type="text"
        icon={<QuestionCircleOutlined />}
        size="large"
        shape="circle"
      />
    </Popover>
  );
};
