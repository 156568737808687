import { useMemo, useState } from "react";
import { AbTestingExperimentFilter } from "../../utils";
import { caseInsensitiveStringComparator, unique } from "helpers/array";
import { AbTestingExperiment } from "gql";
import { AutoComplete, Col, Row, Select, Space } from "antd-4.21.7";
import { EXPERIMENT_TYPE_LABELS } from "pages/editing/components/ABTestForm/config";
import { ExperimentTypeName, SelectOption } from "pages/editing/types";

interface ABTestsFilterProps {
  experiments: (AbTestingExperiment & { regions: string[] })[];
  setFilter: (
    filter: (filter: AbTestingExperimentFilter) => AbTestingExperimentFilter
  ) => void;
}

export const ABTestsFilter = ({
  experiments,
  setFilter,
}: ABTestsFilterProps) => {
  const [nameOptions, setNameOptions] = useState<SelectOption[]>([]);
  const [gaExpIdOptions, setGaExpIdOptions] = useState<SelectOption[]>([]);

  const regionOptions = useMemo<SelectOption[]>(() => {
    if (!experiments?.length) {
      return [];
    }
    const regions: string[] = experiments.flatMap(
      (experiment) => experiment.regions
    );
    const uniqRegions: string[] = unique(regions);
    uniqRegions.sort(caseInsensitiveStringComparator);
    return uniqRegions.map((name) => ({ value: name }));
  }, [experiments]);

  const viewNameOptions = useMemo<SelectOption[]>(() => {
    if (!experiments?.length) {
      return [];
    }
    const viewNames: string[] = experiments.flatMap(
      (experiment) => experiment.viewNames
    );
    const uniqViewNames: string[] = unique(viewNames);
    uniqViewNames.sort(caseInsensitiveStringComparator);
    return uniqViewNames.map((name) => ({ value: name }));
  }, [experiments]);

  const handleSearchByName = (value: string) => {
    if (!value) {
      setNameOptions([]);
    } else {
      const experimentNames: string[] = experiments
        .map((experiment) => experiment.name)
        .filter(
          (name) => name.toLowerCase().indexOf(value.toLowerCase()) !== -1
        );
      const uniqExperimentNames: string[] = unique(experimentNames);
      uniqExperimentNames.sort(caseInsensitiveStringComparator);
      setNameOptions(uniqExperimentNames.map((name) => ({ value: name })));
    }
  };

  const handleSearchByGaExperimentId = (value: string) => {
    if (!value) {
      setGaExpIdOptions([]);
    } else {
      const gaExpIds: string[] = experiments
        .map((experiment) => experiment.gaExperimentId)
        .filter(
          (name) => name.toLowerCase().indexOf(value.toLowerCase()) !== -1
        );
      const uniqGaExpIds: string[] = unique(gaExpIds);
      uniqGaExpIds.sort(caseInsensitiveStringComparator);
      setGaExpIdOptions(uniqGaExpIds.map((name) => ({ value: name })));
    }
  };

  return (
    <Row style={{ marginBottom: "10px" }}>
      <Col>
        <Space>
          <AutoComplete
            style={{ width: 300 }}
            options={nameOptions}
            onSearch={handleSearchByName}
            onChange={(value: string) =>
              setFilter((filter) => ({
                ...filter,
                name: value,
              }))
            }
            placeholder="Name"
            allowClear
          />
          <AutoComplete
            style={{ width: 200 }}
            options={gaExpIdOptions}
            onSearch={handleSearchByGaExperimentId}
            onChange={(value: string) =>
              setFilter((filter) => ({
                ...filter,
                gaExperimentId: value,
              }))
            }
            placeholder="GA Experiment ID"
            allowClear
          />
          <Select
            style={{ width: 120 }}
            options={[
              {
                value: "false",
                label: EXPERIMENT_TYPE_LABELS[ExperimentTypeName.SIMPLE],
              },
              {
                value: "true",
                label: EXPERIMENT_TYPE_LABELS[ExperimentTypeName.MULTI_STEP],
              },
            ]}
            onChange={(value: string) =>
              setFilter((filter) => ({
                ...filter,
                multiStep: value === "true",
              }))
            }
            placeholder="Type"
            allowClear
          />
          <Select
            mode="multiple"
            style={{ width: 200 }}
            options={regionOptions}
            onChange={(value: string[]) =>
              setFilter((filter) => ({
                ...filter,
                regions: value,
              }))
            }
            placeholder="Regions"
            allowClear
          />
          <Select
            mode="multiple"
            style={{ width: 300 }}
            options={viewNameOptions}
            onChange={(value: string[]) =>
              setFilter((filter) => ({
                ...filter,
                viewNames: value,
              }))
            }
            placeholder="View Names"
            allowClear
          />
        </Space>
      </Col>
    </Row>
  );
};
