import { Radio, Row } from "antd-4.21.7";

export const RadioGroupComponent = ({
  inputRef,
  isDisabled,
  options,
  onBlur,
  onChange,
  type,
  value,
  dataQa = "radio",
}) => {
  const inputProps = {
    "data-qa-selector": dataQa,
    disabled: isDisabled,
    onBlur,
    onChange,
    type,
    value,
  };
  return (
    <Radio.Group {...inputProps} ref={inputRef}>
      {options.map((option) => (
        <Radio {...option} key={option.value}>
          {option.label}
        </Radio>
      ))}
    </Radio.Group>
  );
};
