import { Routes, Route } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ABTestCreate } from "./pages/editing/ABTestCreate";
import { ABTestEdit } from "./pages/editing/ABTestEdit";
import { ABTestsOverview } from "./pages/ABTestsOverview";
import { ROUTES_CONFIG } from "./routes";
import CLIENT from "gql/client";
import { ConfigProvider, notification } from "antd-4.21.7";

export const ANTD_PREFIX = "antd-4-21-7";

notification.config({
  top: 55,
  prefixCls: `${ANTD_PREFIX}-notification`,
});

export default function Root() {
  return (
    <QueryClientProvider client={CLIENT}>
      <ConfigProvider prefixCls={ANTD_PREFIX}>
        <BrowserRouter>
          <Routes>
            <Route
              path={ROUTES_CONFIG.OVERVIEW}
              element={<ABTestsOverview />}
            />
            <Route path={ROUTES_CONFIG.CREATE} element={<ABTestCreate />} />
            <Route path={ROUTES_CONFIG.EDIT} element={<ABTestEdit />} />
          </Routes>
        </BrowserRouter>
      </ConfigProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
