import React, { ReactElement } from "react";
import { useAllowedPermissions } from "./hooks/useAllowedPermissions";

type Props = {
  allow: string[] | string;
  domain?: string[] | string;
  children?: ReactElement;
};

export const ACL: React.FC<Props> = ({ children, allow, domain }) => {
  const { isAllowed, isLoading } = useAllowedPermissions(allow, domain);

  if (isAllowed && !isLoading) {
    return children;
  }

  return null;
};
