import { generatePath } from "react-router";
import { Params } from "react-router-dom";

const root = "/:lang";
const abTestingPath = `${root}/ab-testing`;

export const ROUTES_CONFIG = {
  OVERVIEW: `${abTestingPath}/`,
  CREATE: `${abTestingPath}/create`,
  EDIT: `${abTestingPath}/:id/edit`,
  BO_USER_VIEW: `${root}/admin/users/:id/`,
};

export const createPath =
  <P extends Params>(path: string) =>
  (params?: P) => {
    return generatePath(path, { ...params, lang: "en" });
  };

export const ROUTES = {
  overview: createPath(ROUTES_CONFIG.OVERVIEW),
  create: createPath(ROUTES_CONFIG.CREATE),
  edit: createPath<{ id: string }>(ROUTES_CONFIG.EDIT),
  boUserView: createPath<{ id: string }>(ROUTES_CONFIG.BO_USER_VIEW),
};
