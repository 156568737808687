import { FC } from "react";
import { Form } from "antd-4.21.7";
import { formItemLayout } from "controls/ControlGroup/ControlGroup";

export const IndexRow: FC = ({ children }) => (
  <Form.Item
    {...formItemLayout}
    label={
      <strong style={{ whiteSpace: "break-spaces" }}>GA Variation ID</strong>
    }
    labelAlign="right"
  >
    {children}
  </Form.Item>
);
