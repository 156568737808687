import { ClearOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Space, Typography } from "antd-4.21.7";
import { formItemTailLayout } from "controls/ControlGroup/ControlGroup";
import { CustomInputFormController } from "controls/Input";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AbTestFormType } from "../ABTestForm/types";
import { validationResolver } from "./validation";

export interface DeleteParticipantsFormProps {
  actionType: AbTestFormType;
  onSubmit: (deleteParticipantsData) => void;
}

export const DeleteParticipantsForm: FC<DeleteParticipantsFormProps> = ({
  actionType,
  onSubmit,
}) => {
  const form = useForm({
    mode: "onBlur",
    resolver: validationResolver,
  });
  const { isDirty } = form.formState;

  const triggerFormValidation = () => {
    form.trigger();
  };

  return (
    actionType === "edit" && (
      <>
        <Row justify="center">
          <Col>
            <Typography.Title level={2}>
              <DeleteOutlined /> Delete Participants
            </Typography.Title>
          </Col>
        </Row>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <CustomInputFormController
              label="Order ID"
              name="orderId"
              type="text"
              dataQa="order-id"
              onChange={triggerFormValidation}
            />

            <CustomInputFormController
              label="AH User ID"
              name="userId"
              type="text"
              dataQa="user-id"
              tooltip="Autohero user ID (not Backoffice user)"
              onChange={triggerFormValidation}
            />

            <CustomInputFormController
              label="Browser ID"
              name="browserId"
              type="text"
              dataQa="browser-id"
              onChange={triggerFormValidation}
            />

            <Form.Item {...formItemTailLayout}>
              <Space size="small">
                <Button
                  data-qa-selector="delete-participants"
                  disabled={!isDirty}
                  type="primary"
                  htmlType="submit"
                  icon={<DeleteOutlined />}
                >
                  Delete Participants
                </Button>

                <Button
                  data-qa-selector="reset-delete-participants"
                  disabled={!isDirty}
                  icon={<ClearOutlined />}
                  type="default"
                  htmlType="reset"
                  danger
                  onClick={() => form.reset({})}
                >
                  Clear
                </Button>
              </Space>
            </Form.Item>
          </form>
        </FormProvider>
      </>
    )
  );
};
