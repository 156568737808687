import { AbTestingExperimentFragment } from "./fragments";

export const CREATE_EXPERIMENT = `
  mutation createAbTestingExperiment($input: AbTestingExperimentInputInput!) {
    createAbTestingExperiment(input: $input) {
      ...AbTestingExperimentFragment
    }
  }
  ${AbTestingExperimentFragment}
`;

export const UPDATE_EXPERIMENT = `
  mutation updateAbTestingExperiment(
    $id: UUID!
    $input: AbTestingExperimentInputInput!
  ) {
    updateAbTestingExperiment(id: $id, input: $input) {
      ...AbTestingExperimentFragment
    }
  }
  ${AbTestingExperimentFragment}
`;

export const DELETE_EXPERIMENT = `
  mutation deleteAbTestingExperiment(
    $id: UUID!, 
    $environment: String
  ) {
    deleteAbTestingExperiment(id: $id, environment: $environment)
  }
`;

export const DELETE_EXPERIMENT_COUNT = `
  mutation deleteAbTestingExperimentCounters(
    $id: UUID!, 
    $environment: String
  ) {
    deleteAbTestingExperimentCounters(id: $id, environment: $environment)
  }
`;

export const DELETE_PARTICIPANTS = `
  mutation deleteAbTestingParticipants(
    $experimentName: String!,
    $visitorId: String,
    $userId: UUID,
    $orderId: UUID,
    $environment: String
  ) {
    deleteAbTestingParticipants(
      experimentName: $experimentName,
      visitorId: $visitorId,
      userId: $userId,
      orderId: $orderId,
      environment: $environment
    )
  }
`;
