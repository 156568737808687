import { FC } from "react";
import { Link } from "react-router-dom";
import { Card, Descriptions, Divider, Empty } from "antd-4.21.7";

import { ROUTES } from "../../../routes";
import { formatDateTime } from "helpers/date";
import { ExperimentAuthorItem } from "../types";
import { UserOutlined } from "@ant-design/icons";

const NOT_AVAILABLE = "N/A";

interface AuthorsProps {
  loading?: boolean;
  list?: ExperimentAuthorItem[];
}

interface AuthorProps extends ExperimentAuthorItem {
  index: number;
}

const Author: FC<AuthorProps> = ({ id, name, email, date, title, index }) => {
  const path = ROUTES.boUserView({ id });

  return (
    <Descriptions
      title={<span style={{ fontSize: "14px" }}>{title}:</span>}
      column={1}
    >
      <Descriptions.Item label="Name">
        {name ? <Link to={path}>{name}</Link> : NOT_AVAILABLE}
      </Descriptions.Item>
      <Descriptions.Item label="E-Mail">
        {email ? email : NOT_AVAILABLE}
      </Descriptions.Item>
      <Descriptions.Item
        label="Date"
        style={index === 0 ? { paddingBottom: 0 } : null}
      >
        {date ? formatDateTime(date) : NOT_AVAILABLE}
      </Descriptions.Item>
    </Descriptions>
  );
};

const Authors: FC<AuthorsProps> = ({ list, loading }) => {
  return (
    <Card
      title={
        <div style={{ textAlign: "center" }}>
          <UserOutlined /> Authors
        </div>
      }
      loading={loading}
    >
      {list.map(({ id, ...rest }, index) => (
        <div key={index}>
          {index > 0 && <Divider dashed />}
          <Author key={id} id={id} {...rest} index={index} />
        </div>
      ))}
      {!loading && !list.length && (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </Card>
  );
};

export default Authors;
