import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  StopOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { Table, TableProps, Button, Popconfirm, Tag, Space } from "antd-4.21.7";
import { formatDateTime } from "helpers/date";
import {
  AbTestingExperimentWithRegions,
  isExperimentActive,
} from "../../utils";
import { MfeLink } from "components/MfeLink";
import { AbTestingExperiment } from "gql";
import { ROUTES } from "../../../../routes";
import { PERMISSIONS } from "components/ACL";
import { useAllowedPermissions } from "components/ACL/hooks/useAllowedPermissions";
import { EXPERIMENT_TYPE_LABELS } from "pages/editing/components/ABTestForm/config";
import { ExperimentTypeName } from "pages/editing/types";

interface ABTestsTableProps {
  experiments: TableProps<AbTestingExperimentWithRegions>[];
  isLoading: boolean;
  onDelete?: (id: string) => void;
  "data-qa-selector": string;
}

export const ABTestsTable = (props: ABTestsTableProps) => {
  const { isAllowed: isViewAllowed } = useAllowedPermissions(
    PERMISSIONS.QUERY_EXPERIMENT
  );
  const { isAllowed: isEditAllowed } = useAllowedPermissions(
    PERMISSIONS.UPDATE_EXPERIMENT
  );
  const { isAllowed: isDeleteAllowed } = useAllowedPermissions(
    PERMISSIONS.DELETE_EXPERIMENT
  );
  const shouldShowAction = isEditAllowed || isViewAllowed;

  return (
    <Table
      dataSource={props.experiments}
      loading={props.isLoading}
      pagination={false}
      rowKey="id"
      data-qa-selector={props["data-qa-selector"]}
      rowClassName={(_, idx) => `qa-selector-row-${idx}`}
      style={{ marginBottom: 30 }}
    >
      <Table.Column
        key="name"
        dataIndex="name"
        title="Name"
        width={250}
        render={(name) => <span data-qa-selector="cell-name">{name}</span>}
      />
      <Table.Column
        key="regions"
        dataIndex="regions"
        title="Regions"
        width={90}
        align="center"
        ellipsis
        render={(regions) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {regions.map((region: string) => (
              <Tag
                key={region}
                data-qa-selector="cell-region"
                style={{ marginBottom: 5 }}
              >
                {region}
              </Tag>
            ))}
          </div>
        )}
      />
      <Table.Column
        key="multiStep"
        dataIndex="multiStep"
        title="Type"
        width={95}
        align="center"
        render={(isMultiStep: boolean) =>
          isMultiStep ? (
            <span data-qa-selector="cell-type">
              {EXPERIMENT_TYPE_LABELS[ExperimentTypeName.MULTI_STEP]}
            </span>
          ) : (
            <span data-qa-selector="cell-type">
              {EXPERIMENT_TYPE_LABELS[ExperimentTypeName.SIMPLE]}
            </span>
          )
        }
      />
      <Table.Column
        key="viewNames"
        dataIndex="viewNames"
        title="View Names"
        width={250}
        align="center"
        ellipsis
        render={(viewNames = []) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {viewNames.map((viewName) => (
              <Tag
                key={viewName}
                data-qa-selector="cell-view-name"
                style={{ marginBottom: 5 }}
              >
                {viewName}
              </Tag>
            ))}
          </div>
        )}
      />
      <Table.Column
        key="trafficPercents"
        dataIndex="trafficPercents"
        title="Traffic"
        align="center"
        width={80}
        render={(trafficPercents) => (
          <span data-qa-selector="cell-traffic">{trafficPercents || 0}%</span>
        )}
      />
      <Table.Column
        dataIndex="startAt"
        title="Starts"
        width={115}
        align="center"
        render={(date) => (
          <span data-qa-selector="cell-startAt">{formatDateTime(date)}</span>
        )}
      />
      <Table.Column
        dataIndex="expireAt"
        title="Expires"
        width={115}
        align="center"
        render={(date) => (
          <span data-qa-selector="cell-expireAt">{formatDateTime(date)}</span>
        )}
      />
      <Table.Column
        key="enabled"
        dataIndex="enabled"
        title="Enabled"
        width={90}
        align="center"
        render={(enabled: boolean) =>
          enabled ? (
            <CheckCircleOutlined
              style={{ fontSize: "20px" }}
              data-qa-selector="cell-enabled"
              data-qa-selector-name="check"
            />
          ) : (
            <StopOutlined
              style={{ fontSize: "20px" }}
              data-qa-selector="cell-enabled"
              data-qa-selector-name="close"
            />
          )
        }
      />
      <Table.Column
        align="center"
        key="actions"
        render={(_, experiment: AbTestingExperiment) => (
          <Space size="small">
            {shouldShowAction && (
              <MfeLink
                to={ROUTES.edit({ id: experiment.id })}
                data-qa-selector="cell-edit"
                title={isEditAllowed ? "Edit" : "View"}
                icon={isEditAllowed ? <EditOutlined /> : <EyeOutlined />}
                size="large"
              />
            )}
            {isDeleteAllowed && !isExperimentActive(experiment) && (
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => props.onDelete(experiment.id)}
                okText="Yes"
                cancelText="No"
                icon={<DeleteOutlined />}
                okButtonProps={{ loading: props.isLoading }}
              >
                <Button title="Delete" icon={<DeleteOutlined />} size="large" />
              </Popconfirm>
            )}
          </Space>
        )}
      />
    </Table>
  );
};
