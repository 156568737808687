import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Typography, message, Row, Col, Space } from "antd-4.21.7";
import memoizeOne from "memoize-one";
import { PageLayout } from "components/PageLayout";
import { ABTestForm } from "../components/ABTestForm";
import { useDeleteExperimentCounters } from "../hooks/useDeleteExperimentCounters";
import { useAllowedPermissions } from "components/ACL/hooks/useAllowedPermissions";
import { PERMISSIONS } from "components/ACL";
import { BackButton } from "../components/BackButton/BackButton";
import Counters from "./Counters";
import Authors from "./Authors";
import { getStatisticList } from "../utils/getStatisticList";
import { useInitialFormValues } from "../hooks/useInitialFormValues";
import { DEFAULT_MESSAGE_STYLES } from "constants/message";
import { useUpdateExperiment } from "../hooks/useUpdateExperiment";
import { ExperimentAuthorItem } from "../types";
import { useExperimentCounters } from "../hooks/useExperimentCounters";
import { useExperiment } from "../hooks/useExperiment";

export const getExperimentAuthorItemsMemoized = memoizeOne(
  (experiment): ExperimentAuthorItem[] =>
    [
      {
        ...experiment?.createdBy,
        date: experiment?.createdOn,
        title: "Created By",
      },
      {
        ...experiment?.updatedBy,
        date: experiment?.updatedOn,
        title: "Updated By",
      },
    ].filter(({ id }) => !!id)
);

export const ABTestEdit = () => {
  const params = useParams<"id">();

  const onComplete = useCallback((error) => {
    if (!error) {
      message.success({
        content: "Experiment was successfully updated",
        style: DEFAULT_MESSAGE_STYLES,
      });
    } else {
      message.error({
        content: error,
        style: DEFAULT_MESSAGE_STYLES,
      });
    }
  }, []);

  const { experiment, isLoading: isExperimentLoading } = useExperiment(
    params.id
  );

  const { updateExperiment, isLoading: isUpdateExperimentInProgress } =
    useUpdateExperiment(params.id, onComplete);

  const initialFormValues = useInitialFormValues({
    experiment,
    isExperimentLoading,
  });

  const { isAllowed: isEditAllowed, isLoading: isEditPermissionLoading } =
    useAllowedPermissions(PERMISSIONS.UPDATE_EXPERIMENT);

  const formType = isEditAllowed ? "edit" : "view";

  const { data: countersData, isLoading: isCountersLoading } =
    useExperimentCounters(params.id);

  const { deleteExperimentCounters, isLoading: isCountersDeleteInProgress } =
    useDeleteExperimentCounters(params.id);

  const counters = getStatisticList({
    totalHits: countersData?.totalHits,
    counters: countersData?.counters,
    variants: experiment?.multiStep
      ? experiment?.nextStepVariants
      : experiment?.variants,
  });

  const isLoading =
    isEditPermissionLoading ||
    isExperimentLoading ||
    isUpdateExperimentInProgress ||
    isCountersLoading ||
    isCountersDeleteInProgress ||
    !initialFormValues;

  return (
    <PageLayout isLoading={isLoading}>
      <Row justify="center" style={{ marginBottom: "20px" }}>
        <Col>
          <BackButton dataQa="back-to-list-top" />
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          <Typography.Title level={1}>
            {!isLoading &&
              (isEditAllowed ? (
                <>
                  <EditOutlined /> Edit Experiment
                </>
              ) : (
                <>
                  <EyeOutlined /> View Experiment
                </>
              ))}
          </Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col
          xs={{ span: 24, offset: 0, order: 2 }}
          lg={{ span: 15, offset: 1, order: 1 }}
          xl={{ span: 12, offset: 4, order: 1 }}
          xxl={{ span: 10, offset: 6, order: 1 }}
        >
          {initialFormValues && (
            <ABTestForm
              actionType={formType}
              formValues={initialFormValues}
              onSubmit={updateExperiment}
            />
          )}
        </Col>
        <Col
          xs={{ span: 12, offset: 6, order: 1 }}
          lg={{ span: 7, offset: 1, order: 2 }}
          xl={{ span: 6, offset: 2, order: 2 }}
          xxl={{ span: 4, offset: 4, order: 1 }}
        >
          <Counters
            data={counters}
            onDeleteCounters={deleteExperimentCounters}
            loading={isCountersLoading || isCountersDeleteInProgress}
          />
          <Authors
            loading={isExperimentLoading}
            list={getExperimentAuthorItemsMemoized(experiment)}
          />
        </Col>
      </Row>
    </PageLayout>
  );
};
