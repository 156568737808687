import { FC } from "react";
import { Button, Card, Statistic, Empty } from "antd-4.21.7";
import { BarChartOutlined, DeleteOutlined } from "@ant-design/icons";
import { ACL, PERMISSIONS } from "components/ACL";
import { StatisticsItem } from "../utils/getStatisticList";
import { toPercents } from "helpers/math";

interface CountersProps {
  data: StatisticsItem[];
  onDeleteCounters: () => void;
  loading?: boolean;
}

const Counters: FC<CountersProps> = ({
  data,
  onDeleteCounters: onReset,
  loading,
}) => {
  const isResetAvailable: boolean =
    !!data && data?.some?.(({ value }) => Number.isInteger(value) && value > 0);
  return (
    <Card
      title={
        <>
          <BarChartOutlined /> Statistics
        </>
      }
      loading={loading}
      style={{ textAlign: "center", marginBottom: "25px" }}
    >
      {Array.isArray(data) && data.length && (
        <>
          {data.map(({ name, value = 0, total = 0 }) => (
            <Statistic
              key={name}
              title={name}
              value={`${value}/${total} - ${
                total > 0 ? toPercents(value / total) : 0
              }%`}
            />
          ))}
          <ACL allow={PERMISSIONS.DELETE_EXPERIMENT_COUNTERS}>
            <Button
              htmlType="button"
              type="default"
              disabled={!isResetAvailable}
              onClick={onReset}
              danger
              style={{ marginTop: "15px" }}
              icon={<DeleteOutlined />}
            >
              Delete Statistics
            </Button>
          </ACL>
        </>
      )}

      {!loading && !data?.length && (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </Card>
  );
};

export default Counters;
