import { Checkbox, Input } from "antd-4.21.7";
import React from "react";

const inputByType = {
  text: Input,
  area: Input.TextArea,
  checkbox: Checkbox,
};

export const InputComponent = ({
  inputRef,
  isDisabled,
  maxLength,
  onBlur,
  onChange,
  placeholder,
  type,
  value,
  dataQa = "input",
  required = false,
  hasFeedback = false,
  name,
  min,
  max,
}) => {
  const inputProps = {
    "data-qa-selector": dataQa,
    disabled: isDisabled,
    maxLength,
    onBlur,
    placeholder,
    type,
    value,
    checked: value,
    required,
    name,
    min,
    max,
  };

  const InputComponent = inputByType[type] || Input;
  return (
    <InputComponent
      {...inputProps}
      ref={inputRef}
      suffix={hasFeedback ? undefined : <span />}
      onChange={(e) => {
        onChange(e);
        onBlur();
      }}
    />
  );
};
