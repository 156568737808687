export type LoopThroughObjectCallback = (
  key: string,
  obj: any,
  parentObj: any,
  result: any
) => void;

export const loopThroughObject = (
  obj: any,
  parentObj: any,
  result: any,
  callback: LoopThroughObjectCallback
) => {
  for (const key in obj) {
    if (typeof obj[key] == "object" && obj[key] !== null) {
      loopThroughObject(obj[key], obj, result, callback);
    } else if (obj.hasOwnProperty(key)) {
      callback(key, obj, parentObj, result);
    }
  }
};
