import { useContext, useEffect, useRef } from "react";
import { Card } from "antd-4.21.7";
import { useFormContext } from "react-hook-form";
import { VariantsFieldsContext } from "../config";
import { CustomInputFormController } from "controls/Input";
import CustomSelectFormController from "controls/Select";
import { IndexRow } from "../IndexRow";
import { useExperimentRelatedData } from "../../../hooks/useExperimentRelatedData";
import { arraysEqual } from "helpers/array";

export const MultiVariant = ({ index }) => {
  const {
    formState: { errors },
    watch,
    trigger,
  } = useFormContext();

  const { fieldName, isDisabled } = useContext(VariantsFieldsContext);
  const experimentRelatedData = useExperimentRelatedData();

  const variants = watch(fieldName);
  const { nextStep, skipSteps } = variants[index];
  const currentVariantRecord = [nextStep || "", skipSteps || []];

  const isDuplicate =
    variants.filter(({ nextStep, skipSteps }) =>
      arraysEqual(currentVariantRecord, [nextStep || "", skipSteps || []])
    ).length > 1;

  const triggerNextStepVariantValidation = () => {
    trigger(fieldName);
  };

  return (
    <Card
      data-qa-selector={`next-step-variant-${index}`}
      key={index}
      style={{
        margin: "8px 0",
        ...(index % 2 === 0 ? { backgroundColor: "rgba(0, 0, 0, 0.02)" } : {}),
      }}
    >
      <IndexRow>{index}</IndexRow>
      <CustomSelectFormController
        label="Next Step"
        name={`${fieldName}.${index}.nextStep`}
        type="text"
        isDisabled={isDisabled}
        dataQa="next-step-variant"
        error={errors[fieldName]?.[index]?.nextStep}
        options={experimentRelatedData.nextStepOptions}
        onChange={triggerNextStepVariantValidation}
        {...(isDuplicate
          ? {
              hasFeedback: true,
              validateStatus: "warning",
              help: '"Next Step" and "Skip Steps" combination should be unique',
            }
          : {})}
      />

      <CustomSelectFormController
        label="Skip Steps"
        name={`${fieldName}.${index}.skipSteps`}
        type="text"
        mode="multiple"
        isDisabled={isDisabled}
        dataQa="skip-steps"
        error={errors[fieldName]?.[index]?.skipSteps}
        options={experimentRelatedData.skipStepOptions}
        onChange={triggerNextStepVariantValidation}
      />

      <CustomInputFormController
        name={`${fieldName}.${index}.weightPercents`}
        label="Weight Percents"
        type="number"
        min="1"
        max="99"
        isDisabled={isDisabled}
        error={errors[fieldName]?.[index]?.weightPercents}
        dataQa="weight-percents"
        onChange={triggerNextStepVariantValidation}
        required
      />
    </Card>
  );
};
