import { LOAD_USER } from "gql/queries";
import { useQuery } from "react-query";
import { getGQLPermissions, hasPermissions } from "../helpers/common";

type EffectivePermissionResponse = {
  me: {
    effectivePermissions: string[];
  };
};

export const useAllowedPermissions = (
  allow: string | string[],
  domain?: string | string[]
) => {
  const { data, isLoading } = useQuery<EffectivePermissionResponse>(LOAD_USER, {
    refetchOnWindowFocus: false,
  });

  const allowPerms = getGQLPermissions(allow, domain);
  const userPerms = data?.me.effectivePermissions;

  const isValid = hasPermissions(userPerms, allowPerms);

  return {
    isAllowed: isValid,
    isLoading: isLoading,
  };
};
