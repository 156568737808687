import { Form } from "antd-4.21.7";
import React from "react";

import { ControlGroupType } from "./types";

export const formItemLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 17, offset: 0 },
  },
  labelCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 7, offset: 0 },
  },
  style: { marginBottom: "8px" },
};

export const formItemTailLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 17, offset: 7 },
  },
  style: { marginBottom: "8px" },
};

const styles = {
  error: {
    color: "red",
  },
};

export const ControlGroup: React.FC<ControlGroupType> = ({
  label,
  element,
  error,
  subTitle,
  required,
  name,
  tooltip,
  hasFeedback,
  validateStatus,
  help,
}) => {
  return (
    <Form.Item
      {...formItemLayout}
      label={<strong style={{ whiteSpace: "break-spaces" }}>{label}</strong>}
      labelAlign="right"
      colon={Boolean(label)}
      htmlFor={name}
      required={required}
      tooltip={tooltip}
      hasFeedback={hasFeedback}
      validateStatus={validateStatus}
      help={help}
      data-qa-selector="control-group"
    >
      {subTitle && <div>{subTitle}</div>}
      {element}
      {error && (
        <div style={styles.error} data-qa-selector="error">
          {error.message}
        </div>
      )}
    </Form.Item>
  );
};
