import { config } from "config";
import { DELETE_EXPERIMENT_COUNT } from "gql/mutations";
import { GET_EXPERIMENT_COUNTERS } from "gql/queries";
import { useMutationFactory } from "hooks/useMutationFactory";

export const useDeleteExperimentCounters = (experimentId: string) => {
  const { mutate, isLoading } = useMutationFactory({
    query: DELETE_EXPERIMENT_COUNT,
    invalidateQueries: [GET_EXPERIMENT_COUNTERS],
  });

  const deleteExperimentCounters = async () => {
    await mutate({ id: experimentId, environment: config.APP_ENV });
  };

  return {
    deleteExperimentCounters,
    isLoading,
  };
};
