import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd-4.21.7";
import { ROUTES } from "../../../../routes";

type BackButtonProps = {
  dataQa: string;
};
export const BackButton: React.FC<BackButtonProps> = ({ dataQa }) => (
  <Button
    data-qa-selector={dataQa}
    type="default"
    href={ROUTES.overview()}
    icon={<ArrowLeftOutlined />}
  >
    Back to Experiments
  </Button>
);
