import { CREATE_EXPERIMENT } from "gql/mutations";
import { GET_EXPERIMENT, GET_EXPERIMENTS } from "gql/queries";
import {
  UseConditionBuilderOutput,
  useConditionBuilder,
} from "components/ConditionBuilder";
import { AbTestingExperimentFormValues } from "../types";
import { createExperimentInput } from "../utils/experimentFormValues";
import { CompleteFunction, useMutationFactory } from "hooks/useMutationFactory";

export const useCreateExperiment = (onComplete?: CompleteFunction) => {
  const { mutate, isLoading } = useMutationFactory({
    query: CREATE_EXPERIMENT,
    responseFieldName: "createAbTestingExperiment",
    onComplete,
    invalidateQueries: [GET_EXPERIMENTS, GET_EXPERIMENT],
  });
  const conditionBuilder = useConditionBuilder();

  const createExperiment = async (
    formValues: AbTestingExperimentFormValues
  ) => {
    const experiment = createExperimentInput(
      (conditionBuilder as UseConditionBuilderOutput).stringifyCondition,
      formValues
    );

    await mutate({ input: experiment });
  };

  return {
    createExperiment,
    isLoading,
  };
};
