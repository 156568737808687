import { UPDATE_EXPERIMENT } from "gql/mutations";
import {
  UseConditionBuilderOutput,
  useConditionBuilder,
} from "components/ConditionBuilder";
import { AbTestingExperimentFormValues } from "../types";
import { createExperimentInput } from "../utils/experimentFormValues";
import { CompleteFunction, useMutationFactory } from "hooks/useMutationFactory";
import { useExperiment } from "./useExperiment";
import {
  GET_EXPERIMENT,
  GET_EXPERIMENTS,
  GET_EXPERIMENT_COUNTERS,
} from "gql/queries";

export const useUpdateExperiment = (
  existingId: string,
  onComplete?: CompleteFunction
) => {
  const { mutate, isLoading } = useMutationFactory({
    query: UPDATE_EXPERIMENT,
    responseFieldName: "updateAbTestingExperiment",
    onComplete,
    invalidateQueries: [
      GET_EXPERIMENTS,
      GET_EXPERIMENT,
      GET_EXPERIMENT_COUNTERS,
    ],
  });
  const conditionBuilder = useConditionBuilder();

  const updateExperiment = async (
    formValues: AbTestingExperimentFormValues
  ) => {
    const experiment = createExperimentInput(
      (conditionBuilder as UseConditionBuilderOutput).stringifyCondition,
      formValues
    );

    await mutate({ id: existingId, input: experiment });
  };

  return {
    updateExperiment,
    isLoading,
  };
};
