import { useQuery } from "react-query";

import { AbTestingExperiment } from "gql/index";
import { GET_EXPERIMENT } from "gql/queries";
import { config } from "config";

type GetExperimentData = {
  experiment: AbTestingExperiment;
};

export const useExperiment = (
  id: string
): { experiment: AbTestingExperiment; isLoading: boolean } => {
  const { data, isFetching } = useQuery<GetExperimentData>(
    [GET_EXPERIMENT, { id, environment: config.APP_ENV }],
    {
      enabled: !!id,
    }
  );

  return {
    experiment: data?.experiment,
    isLoading: isFetching,
  };
};
