import { message } from "antd-4.21.7";

message.config({
  top: 50,
  duration: 3,
  maxCount: 3,
});

export const DEFAULT_MESSAGE_STYLES = {
  zIndex: "1050",
};
