import {
  AbTestingExperimentCountersFragment,
  AbTestingExperimentFragment,
} from "./fragments";

export const GET_EXPERIMENT = `
  query GetExperiment(
    $id: UUID!, 
    $environment: String
  ) {
    experiment: abTestingExperiment(id: $id, environment: $environment) {
      ...AbTestingExperimentFragment
    }
  }
  ${AbTestingExperimentFragment}
`;

export const GET_EXPERIMENT_COUNTERS = `
  query GetExperimentCounters(
    $experimentId: UUID!, 
    $environment: String
  ) {
    abTestingExperimentCounters(experimentId: $experimentId, environment: $environment) {
      ...AbTestingExperimentCountersFragment
    }
  }
  ${AbTestingExperimentCountersFragment}
`;

export const GET_EXPERIMENTS = `
  query GetExperiments($environment: String) {
    abTestingExperiments(environment: $environment) {
      id
      name
      gaExperimentId
      startAt
      expireAt
      enabled
      multiStep
      viewNames
      condition
      trafficPercents
      variants {
        id
      }
      nextStepVariants {
        id
      }
    }
  }
`;

export const GET_EXPERIMENTS_BY_NAME = `
  query GetExperimentsByName(
    $name: String, 
    $environment: String
  ) {
    abTestingExperiments(name: $name, environment: $environment) {
      id
    }
  }
`;

export const SEARCH_EXPERIMENTS = `
  query SearchExperiments($search: SearchProjection2Input!) {
    searchAbTestingExperiments(search: $search) {
      entities {
        id
        name
        gaExperimentId
        startAt
        expireAt
        enabled
        multiStep
        viewNames
        condition
        trafficPercents
        variants {
          id
        }
        nextStepVariants {
          id
        }
      }
    }
  }
`;

export const LOAD_USER = `
  query {
    me {
      accessParameters {
        key
        value
      }
      effectivePermissions
      id
    }
  }
`;

export const LIST_CONDITION_VARS = `
  query ListConditionVars {
    vars: abTestingAvailableConditionVariables {
      type
      values
      variable
    }
  }
`;

export const GET_STEP_TYPES = `
  query GetRetailCheckoutStepTypes {
    retailCheckoutStepTypes {
      steps {
        viewName
      }
    }
  }
`;
