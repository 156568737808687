export interface StatisticsItem {
  name: string;
  value: number;
  total: number;
}

export const getStatisticList = ({
  counters = [],
  variants = [],
  totalHits,
}): StatisticsItem[] => {
  const totalParticipants = counters.reduce((a, b) => a + b, 0);
  return [
    {
      name: "Total Participants",
      value: totalParticipants,
      total: totalHits,
    },
    ...variants.map((_, i) => ({
      name: `Variant #${i} Participants`,
      value: counters[i],
      total: totalParticipants,
    })),
  ];
};
