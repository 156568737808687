export const unique = <T>(arr: T[], mapFn?: (el: T) => any): T[] => {
  const mappedArr = !mapFn ? arr : arr.map(mapFn);
  return mappedArr.filter((el, i) => {
    return mappedArr.indexOf(el) == i;
  });
};

export const caseInsensitiveStringComparator = (a: string, b: string) =>
  a.toLowerCase().localeCompare(b.toLowerCase());

export const arraysEqual = (a: Array<any>, b: Array<any>): boolean => {
  if (a.length !== b.length) {
    return false;
  }
  const aSorted = a.slice().sort();
  const bSorted = b.slice().sort();
  return aSorted.every(function (aValue, index) {
    const bValue = bSorted[index];
    if (Array.isArray(aValue)) {
      return Array.isArray(bValue) && arraysEqual(aValue, bValue);
    } else {
      return aValue === bValue;
    }
  });
};
