import { Select as SelectComponent } from "antd-4.21.7";

const Select = ({
  inputRef,
  isDisabled = false,
  options = [],
  onBlur,
  onChange,
  value,
  name,
  dataQa = "select",
  mode,
}) => {
  const inputProps = {
    "data-qa-selector": dataQa,
    disabled: isDisabled,
    onBlur,
    onChange,
    value,
    name,
    mode,
  };

  return (
    <SelectComponent {...inputProps} ref={inputRef}>
      {options.map((option) => (
        <SelectComponent.Option
          key={`${name}-${option.value}`}
          value={option.value}
        >
          {option.label}
        </SelectComponent.Option>
      ))}
    </SelectComponent>
  );
};

export default Select;
