import React from "react";
import { Button, ButtonProps } from "antd-4.21.7";
import { LinkProps, useHref, useLinkClickHandler } from "react-router-dom";

type ForwardButtonProps = Omit<ButtonProps, "href">;
type RouterLinkProps = Pick<LinkProps, "replace" | "state" | "to">;
type MfeLinkProps = ForwardButtonProps & RouterLinkProps;

export const MfeLink = ({
  to,
  replace = false,
  state,
  onClick,
  ...rest
}: MfeLinkProps) => {
  const href = useHref(to);
  const internalOnClick = useLinkClickHandler(to, {
    replace,
    state,
    target: rest.target,
  });
  return (
    <Button
      href={href}
      onClick={(event) => {
        onClick?.(event);
        if (!event.defaultPrevented) {
          internalOnClick(event as React.MouseEvent<HTMLAnchorElement>);
        }
      }}
      {...rest}
    />
  );
};
