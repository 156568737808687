import memoizeOne from "memoize-one";

import { AbTestingExperiment } from "gql";

import { createExperimentFormValues } from "../utils/experimentFormValues";
import { useConditionBuilder, STATUS } from "components/ConditionBuilder";

interface IUseInitialFormDataOptions {
  experiment?: AbTestingExperiment;
  isExperimentLoading?: boolean;
}

const createExperimentFormValuesMemoized = memoizeOne(
  createExperimentFormValues
);

export const useInitialFormValues = (options?: IUseInitialFormDataOptions) => {
  const conditionBuilder = useConditionBuilder();
  const loading =
    conditionBuilder.state === STATUS.PENDING || options?.isExperimentLoading;

  return !loading
    ? createExperimentFormValuesMemoized(
        conditionBuilder.makeCondition,
        options?.experiment
      )
    : null;
};
