import { DatePicker, Row } from "antd-4.21.7";

export const DatePickerComponent = ({
  inputRef,
  isDisabled,
  onBlur,
  onChange,
  placeholder,
  value,
  showTime = true,
  dataQa = "date",
}) => {
  const inputProps = {
    "data-qa-selector": dataQa,
    disabled: isDisabled,
    onBlur,
    placeholder,
    value,
    showTime,
  };

  return (
    <Row>
      <DatePicker
        {...inputProps}
        ref={inputRef}
        onChange={(event) => {
          onChange(event);
          onBlur();
        }}
      />
    </Row>
  );
};
