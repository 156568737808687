import { useQuery } from "react-query";

import type { AbTestingExperiment } from "gql";
import { GET_EXPERIMENTS } from "gql/queries";
import { config } from "config";

type GetExperimentData = {
  abTestingExperiments: AbTestingExperiment[];
};

export const useExperimentList = () => {
  const { data, isLoading } = useQuery<GetExperimentData>([
    GET_EXPERIMENTS,
    { environment: config.APP_ENV },
  ]);
  return { experiments: data?.abTestingExperiments, isLoading };
};
