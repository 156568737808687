import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Typography, message, Row, Col, Space } from "antd-4.21.7";
import { PageLayout } from "components/PageLayout";
import { DEFAULT_MESSAGE_STYLES } from "constants/message";
import { ROUTES } from "../../../routes";
import { ABTestForm } from "../components/ABTestForm";
import { useCreateExperiment } from "../hooks/useCreateExperiment";
import { BackButton } from "../components/BackButton/BackButton";
import { useInitialFormValues } from "../hooks/useInitialFormValues";

export const ABTestCreate = () => {
  const navigate = useNavigate();

  const onComplete = useCallback(
    (error, { id } = {}) => {
      if (!error) {
        message.success({
          content: "Experiment was successfully created",
          style: DEFAULT_MESSAGE_STYLES,
        });

        navigate(ROUTES.edit({ id }));
      } else {
        message.error({
          content: error,
          style: DEFAULT_MESSAGE_STYLES,
        });
      }
    },
    [navigate]
  );

  const { createExperiment, isLoading: isExperimentLoading } =
    useCreateExperiment(onComplete);

  const initialFormValues = useInitialFormValues();

  const isLoading = isExperimentLoading || !initialFormValues;

  return (
    <PageLayout isLoading={isLoading}>
      <Row justify="center" style={{ marginBottom: "20px" }}>
        <Col>
          <BackButton dataQa="back-to-list-top" />
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          <Typography.Title level={1}>
            <PlusCircleOutlined /> New Experiment
          </Typography.Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={12}>
          {initialFormValues && (
            <ABTestForm
              actionType="create"
              formValues={initialFormValues}
              onSubmit={createExperiment}
            />
          )}
        </Col>
      </Row>
    </PageLayout>
  );
};
