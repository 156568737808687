import { DELETE_EXPERIMENT } from "gql/mutations";
import { GET_EXPERIMENT, GET_EXPERIMENTS } from "gql/queries";
import { config } from "config";
import { useMutationFactory } from "hooks/useMutationFactory";

export const useDeleteExperiment = () => {
  const { mutate, isLoading } = useMutationFactory({
    query: DELETE_EXPERIMENT,
    invalidateQueries: [GET_EXPERIMENTS, GET_EXPERIMENT],
  });

  const deleteExperiment = async (existingId: string) => {
    await mutate({ id: existingId, environment: config.APP_ENV });
  };

  return {
    deleteExperiment,
    isLoading,
  };
};
