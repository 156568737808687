import { DELETE_PARTICIPANTS } from "gql/mutations";
import { config } from "config";
import { CompleteFunction, useMutationFactory } from "hooks/useMutationFactory";

export const useDeleteParticipants = (
  experimentName: string,
  onComplete?: CompleteFunction
) => {
  const { mutate, isLoading } = useMutationFactory({
    query: DELETE_PARTICIPANTS,
    responseFieldName: "deleteAbTestingParticipants",
    onComplete,
  });

  const deleteParticipants = async ({ browserId, userId, orderId }) => {
    await mutate({
      experimentName: experimentName,
      visitorId: browserId,
      userId: userId,
      orderId: orderId,
      environment: config.APP_ENV,
    });
  };

  return {
    deleteParticipants,
    isLoading,
  };
};
