import { FC, useState, useEffect, CSSProperties } from "react";
import { Button, Modal } from "antd-4.21.7";
import { Query, Builder, ImmutableTree } from "react-awesome-query-builder";
import "react-awesome-query-builder/lib/css/styles.css";
import { isConditionEmpty, findConditionErrorMessage } from "./utils";
import { useConditionBuilder } from "./hooks/useConditionBuilder";
import Input from "./components/Input";
import { STATUS } from "./types";

interface ConditionBuilderProps {
  value: ImmutableTree;
  onChange: (condition: ImmutableTree) => void;
}

const QUERY_BUILDER_STYLES = {
  width: "calc(100% + 10px)",
  margin: 0,
} as CSSProperties;

export const ConditionBuilder: FC<ConditionBuilderProps> = ({
  value,
  onChange,
}) => {
  const [isModalOpened, setModalOpened] = useState<boolean>(false);
  const [localValue, setLocalValue] = useState(value);
  const data = useConditionBuilder();
  const isEmpty = isConditionEmpty(value);
  const actionTitle = `${isEmpty ? "Set up" : "Update"} condition`;

  useEffect(() => {
    // skip first render on edit,
    // as default "localValue" is filled by value from props
    if (value !== localValue) {
      setLocalValue(value);
    }
  }, [value]);

  const handleOpen = () => {
    setModalOpened(true);
  };

  const handleClose = () => {
    setModalOpened(false);

    setLocalValue(value);
  };

  const handleSubmit = () => {
    onChange(localValue);

    setModalOpened(false);
  };

  if (data.state === STATUS.PENDING) {
    return <span>Loading...</span>;
  }

  const isSubmitDisabled =
    // direct match
    localValue === value ||
    // e.g. empty form value and empty rule in component form value
    (isConditionEmpty(localValue) && isConditionEmpty(value)) ||
    // invalid condition
    Boolean(findConditionErrorMessage(localValue));

  const SubmitButton = (
    <Button type="primary" onClick={handleSubmit} disabled={isSubmitDisabled}>
      Save
    </Button>
  );

  return (
    <>
      <Input
        onOpen={handleOpen}
        value={data.stringifyCondition(value)}
        title={actionTitle}
        isEmpty={isEmpty}
      />
      <Modal
        width="80vw"
        visible={isModalOpened}
        title={actionTitle}
        onCancel={handleClose}
        footer={SubmitButton}
      >
        <div className="query-builder" style={QUERY_BUILDER_STYLES}>
          <Query
            {...data.builderConfig}
            value={localValue}
            onChange={setLocalValue}
            renderBuilder={(props) => <Builder {...props} />}
          />
        </div>
      </Modal>
    </>
  );
};
