import moment from "moment";

import { AbTestingExperiment, AbTestingExperimentInputInput } from "gql";
import { config } from "config";
import {
  VIEW_NAME_OPTIONS,
  VIEW_VARIANT_OPTIONS,
} from "../components/ABTestForm/config";
import { AbTestingExperimentFormValues, ExperimentTypeName } from "../types";
import { UseConditionBuilderOutput } from "components/ConditionBuilder";

export const createExperimentFormValues = (
  makeCondition: UseConditionBuilderOutput["makeCondition"],
  experiment?: AbTestingExperiment
): AbTestingExperimentFormValues => {
  if (!experiment) {
    return {
      isPlaceholder: true,
      viewNames: [VIEW_NAME_OPTIONS[0].value],
      experimentType: ExperimentTypeName.SIMPLE,
      variantsNumber: 2,
      variants: [
        { viewVariant: VIEW_VARIANT_OPTIONS[0].value, weightPercents: 50 },
        { viewVariant: VIEW_VARIANT_OPTIONS[1].value, weightPercents: 50 },
      ],
      nextStepVariantsNumber: 2,
      nextStepVariants: [
        { nextStep: "", weightPercents: 50 },
        { nextStep: "", weightPercents: 50 },
      ],
      enabled: false,
      startAt: moment().startOf("day"),
      condition: makeCondition(),
      trafficPercents: 20,
    } as AbTestingExperimentFormValues;
  }

  const {
    id: _,
    name,
    enabled,
    environment,
    condition,
    gaExperimentId,
    startAt,
    expireAt,
    multiStep,
    variants,
    nextStepVariants,
    viewNames,
    trafficPercents,
  } = experiment;

  return {
    name,
    gaExperimentId,
    startAt: startAt ? moment(startAt) : moment().startOf("day"),
    expireAt: expireAt ? moment(expireAt) : undefined,
    enabled,
    experimentType: multiStep
      ? ExperimentTypeName.MULTI_STEP
      : ExperimentTypeName.SIMPLE,
    viewNames,
    condition: makeCondition(condition),
    trafficPercents,
    variants,
    variantsNumber: variants?.length || 2,
    nextStepVariants,
    nextStepVariantsNumber: nextStepVariants?.length || 2,
    environment,
  };
};

export const createExperimentInput = (
  stringifyCondition: UseConditionBuilderOutput["stringifyCondition"],
  formValues: AbTestingExperimentFormValues
): AbTestingExperimentInputInput => {
  const {
    name,
    gaExperimentId,
    enabled,
    experimentType,
    viewNames,
    variantsNumber,
    nextStepVariantsNumber,
    condition,
    trafficPercents,
    isPlaceholder: _,
    ...values
  } = formValues;
  const multiStep = formValues.experimentType === ExperimentTypeName.MULTI_STEP;
  const variants = multiStep ? [] : values.variants;
  const nextStepVariants = !multiStep ? [] : values.nextStepVariants;

  return {
    name,
    gaExperimentId,
    startAt: moment(formValues.startAt).format(),
    expireAt: formValues.expireAt ? moment(formValues.expireAt).format() : null,
    enabled,
    multiStep,
    viewNames,
    condition: stringifyCondition(condition),
    trafficPercents,
    variants,
    nextStepVariants,
    environment: config.APP_ENV,
  };
};
