import { ImmutableTree, JsonGroup, Utils } from "react-awesome-query-builder";

const findEmptyFields = (
  children: JsonGroup["children1"],
  emptyFieldsAcc = new Set<string>()
): Set<string> => {
  const list =
    children && (Array.isArray(children) ? children : Object.values(children));

  return (
    list?.reduce((acc, item) => {
      if (item.type !== "rule") {
        return findEmptyFields(item.children1 ?? {}, acc);
      }

      if (
        item.properties.field &&
        item.properties.value.some((val) => val == null)
      ) {
        acc.add(item.properties.field);
      }

      return acc;
    }, emptyFieldsAcc) ?? emptyFieldsAcc
  );
};

const humanizeField = (field: string): string => {
  return field.includes(".") ? field.split(".").join(" > ") : field;
};

export const findConditionErrorMessage = (
  value: ImmutableTree
): null | string => {
  if (!Utils.isValidTree(value)) {
    return "An invalid value is provided to the condition builder";
  }

  const emptyFields = findEmptyFields(Utils.getTree(value).children1);

  if (emptyFields.size === 0) {
    return null;
  }

  const incompleteFields = Array.from(emptyFields)
    .map(humanizeField)
    .join(", ");

  return `The following field${
    emptyFields.size > 1 ? "s are" : " is"
  } incomplete: ${incompleteFields}`;
};

export const isConditionEmpty = (tree: ImmutableTree): boolean => {
  const { children1 } = Utils.getTree(tree);

  const isEmpty = (children: JsonGroup["children1"]) => {
    const items = Array.isArray(children) ? children : Object.values(children);

    return !items.some((item) =>
      item.type !== "group"
        ? item.properties.field != null
        : !isEmpty(item.children1)
    );
  };

  return isEmpty(children1);
};
