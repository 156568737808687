import { CSSProperties } from "react";
import { Tooltip } from "antd-4.21.7";
import { EditTwoTone, PlusCircleTwoTone } from "@ant-design/icons";

const ICON_STYLES = {
  fontWeight: "700",
  fontSize: "24px",
} as CSSProperties;

const CONTAINER_STYLES = {
  position: "absolute",
  right: "-35px",
  top: "calc(50% - 12px)",
} as CSSProperties;

const ActionButton = ({ isEmpty, onClick, title }) => {
  const Icon = isEmpty ? PlusCircleTwoTone : EditTwoTone;
  const handleClick = (event) => {
    event.stopPropagation();

    onClick();
  };

  return (
    <div style={CONTAINER_STYLES}>
      <Tooltip placement="top" title={title}>
        <Icon
          onClick={handleClick}
          style={ICON_STYLES}
          twoToneColor="#1890ff"
        />
      </Tooltip>
    </div>
  );
};

export default ActionButton;
