import handleErrors from "helpers/handleErrors";
import { gqlGatewayUrl } from "constants/endpoints";
import { getRequestHeaders } from "gql/client";
import { useMutation, useQueryClient } from "react-query";
import { gql, request } from "graphql-request";

export type CompleteFunction = (error, data?) => void;

type MutationFactoryProps = {
  query: string;
  responseFieldName?: string;
  onComplete?: CompleteFunction;
  invalidateQueries?: string[];
};

export const useMutationFactory = ({
  query,
  responseFieldName = "",
  onComplete,
  invalidateQueries = [],
}: MutationFactoryProps) => {
  const queryClient = useQueryClient();

  const {
    mutate: mutateFn,
    isLoading,
    isSuccess,
    isError,
  } = useMutation(
    async (data) => {
      try {
        return await request(
          `${gqlGatewayUrl}`,
          gql`
            ${query}
          `,
          data,
          getRequestHeaders()
        );
      } catch (e) {
        if (e.response?.errors?.length) {
          handleErrors(e.response.errors);

          throw e;
        }

        return e.response;
      }
    },
    {
      onSuccess: async (response) => {
        const { data, errors, error } = response;
        const err = errors?.[0] || (error && `Error: ${error}`);

        invalidateQueries.forEach((query) =>
          queryClient.invalidateQueries(query)
        );

        if (onComplete) {
          onComplete(err, data?.[responseFieldName]);
        }
      },
      onError: (error) => {
        if (onComplete) {
          onComplete(error);
        }
      },
    }
  );

  async function mutate(variables) {
    mutateFn(variables);
  }

  return {
    isLoading,
    isSuccess,
    isError,
    mutate,
  };
};
