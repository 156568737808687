import { useQuery } from "react-query";

import { AbTestingExperiment } from "gql";
import { GET_EXPERIMENTS_BY_NAME } from "gql/queries";
import { config } from "config";

type SearchExperimentsData = {
  abTestingExperiments: AbTestingExperiment[];
};

export interface SearchExperimentsFilter {
  name: string;
  excludeId?: string;
}

export const useSearchExperimentsData = (
  filter: SearchExperimentsFilter,
  enabled = false
) => {
  const { data, isLoading, refetch } = useQuery<SearchExperimentsData>(
    [
      GET_EXPERIMENTS_BY_NAME,
      {
        name: filter.name,
        environment: config.APP_ENV,
      },
    ],
    {
      enabled: enabled && !!filter.name,
    }
  );

  const experiments =
    data?.abTestingExperiments?.filter(
      (experiment) => experiment.id !== filter.excludeId
    ) || [];

  return {
    experiments,
    totalCount: experiments.length,
    isLoading,
    refetch,
  };
};
