import { QueryClient } from "react-query";
import { request, gql } from "graphql-request";
import handleErrors from "helpers/handleErrors";

const ACCESS_TOKEN_KEY = "t";
const API_ENDPOINT_SUFFIX = "/api/v1/retail-graphql-gateway";

const getCookieByName = (name = "") => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    return parts.pop().split(";").shift();
  }
};

const staticHeaders = {
  accept: "application/json, text/plain, */*",
  "Content-Type": "application/json",
  "access-domain": "ab.testing.management",
};

export const getRequestHeaders = () => ({
  ...staticHeaders,
  Authorization: `Bearer ${getCookieByName(ACCESS_TOKEN_KEY)}`,
});

export default new QueryClient({
  defaultOptions: {
    mutations: {
      mutationFn: async ({ queryKey: [query, params] }) => {
        try {
          const result = await request(
            `${API_ENDPOINT_SUFFIX}`,
            gql`
              ${query}
            `,
            params,
            getRequestHeaders()
          );
          return result.data;
        } catch (e) {
          if (e.response?.errors?.length) {
            throw e;
          }
          return e.response.data;
        }
      },
    },
    queries: {
      refetchOnWindowFocus: true,
      // By default cached data is considered as stale.
      // A longer staleTime means queries will not refetch their data as often.
      staleTime: 1000 * 60 * 1, // 1 minute
      queryFn: async ({ queryKey: [query, params] }) => {
        try {
          const result = await request(
            `${API_ENDPOINT_SUFFIX}`,
            gql`
              ${query}
            `,
            params,
            getRequestHeaders()
          );
          return result.data;
        } catch (e) {
          if (e.response?.errors?.length) {
            handleErrors(e.response.errors);

            throw e;
          }
          return e.response.data;
        }
      },
    },
  },
});
